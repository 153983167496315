import generateCommissionTableSetting from './_generateCommissionTableSetting';

const tableExportConfig = {
  tableSettingArray: [
    generateCommissionTableSetting({
      class_name: 'AccManServiceOrderDoneCommissionGroupPending',
      commission_class_name: 'AccManServiceOrderDoneCommissionPending',
      user_role_class_name: 'AccountManager',
      user_role_key: 'account_manager',
      user_role_title: 'Account Manager',
      data_row_key: 'service_order_id',
      data_row_title: 'Service Order ID',
      itemTypes: [
        {
          name: 'services',
          relationship_name: 'FromServices',
          data_key: 'from_services',
        },
      ],
    }),
  ],
};
export default tableExportConfig;
