import generateCommissionTableSetting from './_generateCommissionTableSetting';

const tableExportConfig = {
  tableSettingArray: [
    generateCommissionTableSetting({
      class_name: 'DriverOrderDeliveredCommissionGroupPending',
      commission_class_name: 'DriverOrderDeliveredCommissionPending',
      user_role_class_name: 'Driver',
      user_role_key: 'driver',
      user_role_title: 'Driver',
      data_row_key: ['order_delivered_id'],
      data_row_title: 'Order Delivered ID',
      itemTypes: [
        {
          name: 'tangible',
          relationship_name: 'FromTangibles',
          data_key: 'from_tangibles',
        },
        {
          name: 'dispenser',
          relationship_name: 'FromDispensers',
          data_key: 'from_dispensers',
        },
      ],
    }),
  ],
};
export default tableExportConfig;
