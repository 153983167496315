import generateCommissionTableSetting from './_generateCommissionTableSetting';

const tableExportConfig = {
  tableSettingArray: [
    generateCommissionTableSetting({
      class_name: 'SalespersonOrderCommissionGroupPending',
      commission_class_name: 'SalespersonOrderCommissionPending',
      user_role_class_name: 'Salesperson',
      user_role_key: 'salesperson',
      user_role_title: 'Salesperson',
      data_row_key: 'order_id',
      data_row_title: 'Order ID',
      itemTypes: [
        {
          name: 'package',
          relationship_name: 'FromPackages',
          data_key: 'from_packages',
        },
        {
          name: 'tangible',
          relationship_name: 'FromTangibles',
          data_key: 'from_tangibles',
        },
        {
          name: 'voucher',
          relationship_name: 'FromVouchers',
          data_key: 'from_vouchers',
        },
      ],
    }),
  ],
};
export default tableExportConfig;
