<template>
  <div class="animated fadeIn">
    <div v-if="this.error_message">
      <b-card class="m-3">
        <span style="font-size:24px; font-weight: 400">
          <span style="color: red">Error: </span>
          {{ this.error_message }}
        </span>
      </b-card>
    </div>
    <b-card class="m-3" v-if="!tableSettingConfigs[this.class_name]">
      <span style="font-size:24px;">
        <span style="color: red">Error: </span>
        Table Setting not found for {{ this.class_name }}
      </span>
    </b-card>
    <b-card class="table-with-export-feature" v-else>
      <div class="m-2" v-if="!this.$route.params['id']">
        <div>
          <div v-if="is_class_Loading[class_name]">Loading...</div>
        </div>
      </div>
      <div v-if="loading" class="loading-overlay">Updating...</div>
      <div v-if="!is_class_Loading[class_name]">
        <div v-if="error_message" v-html="error_message" class="alert alert-danger"></div>
        <div v-if="success_message" v-html="success_message" class="alert alert-success"></div>
        <div>
          <div>
            <div class="table-export-component">
              <div class="table-export-container mb-3" v-for="(tableSetting) in this.tableSettingConfigs[this.class_name]['tableSettingArray']" :key="tableSetting.id">
                <div class="table-export-wrapper">
                  <div>
                    <h2 v-if="tableSetting['class_name']" class="d-inline-block mr-3">{{ tableSetting['class_name'] }}</h2>
                    <button
                      v-if="dataChecking(descriptor_data, tableSetting['class_name'], 'length')"
                      class="btn btn-primary mb-2"
                      @click="onClickExport(tableSetting)"
                    ><i class="fa fa-print py-1 px-2" />Export to CSV</button>
                  </div>
                  <div
                    v-if="tableSetting['not_ready']"
                    class="table-export-not-ready"
                  >This report is not ready yet.</div>
                  <div
                    v-else-if="!dataChecking(descriptor_data, tableSetting['class_name'], 'length')"
                    class="table-export-no-record"
                  >No Record Found.</div>
                  <div v-else class="table-export-table-item" :ref="'table_export-' + tableSetting.id">
                    <div class="table-export-header">
                      <div
                        :class="'cell ' + field.type + ' ' + (field.createOnly ? 'create-only' : '')"
                        v-for="(field) in tableSetting.fields"
                        :key="field.id"
                        :title="field.title"
                        :style="field.width ? ('width: ' + field.width) : ''"
                      >
                        <div>{{ field.title }}</div>
                      </div>
                    </div>
                    <div
                      class="table-export-row-container"
                      v-for="(row, rowIndex) in dataChecking(descriptor_data, tableSetting['class_name'])"
                      :key="rowIndex"
                    >
                      <div
                        class="table-export-row"
                      >
                        <div
                          :class="'cell ' + field.type + ' ' +  (field.createOnly ? 'create-only' : '')"
                          v-for="(field) in tableSetting.fields"
                          :key="field.id"
                          :style="field.width ? ('width: ' + field.width) : ''"
                        >
                          <div>{{ dataChecking(row, field.datapath) || '-' }}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import _ from 'lodash';
import { dataChecking, exportToCsv, renderDateTimeFormat } from '@/descriptor/coreui/globalUtils';

import tableSettingConfigs from './config/_tableSettingConfigs';

export default {
  components: {

  },
  props: {
    parent_data: {
      type: Object,
      default: null,
    },
    _modal_rel_search_components: {},
    fixed_params: {
      type: Object,
      default: null,
    },
    _read_params: {
      type: Object,
    },
    _immutable: {
      type: Boolean,
      default: false,
    },
    with_childs_mode: {
      //IMPORTANT TODO: this is a temporary solution, use isWithRelationshipsSatisfied instead
      type: Boolean,
      default: false,
    },
    manual_get: {},
    edit_button_label: {
      type: String,
      default: 'Edit',
    },
  },
  data: () => {
    return {
      error_message: null,
      success_message: null,
      loading: null,

      tableSettingConfigs: tableSettingConfigs,

      descriptor_data: {},
      descriptor_error: {},
      is_class_Loading: {},
    };
  },
  computed: {
    class_name: {
      get: function() {
        if (this.$route.params['class_name']) {
          return this.$route.params['class_name'];
        }
        return this.class_name_;
      },
      set: function(class_name) {
        this.class_name_ = class_name;
      },
    },
    class_relationships_slotNames() {
      return _.map(this.class_relationships, (relationship) => relationship['from']['class_name'] + '_' + relationship['relationship_name']);
    },
  },
  created() {
    this.init();
  },
  methods: {
    dataChecking,
    exportToCsv,
    renderDateTimeFormat,
    onClickExport(tableSetting) {
      const rows = dataChecking(this.descriptor_data, tableSetting['class_name']);
        if (rows.length === 0) return;

        const csvHeaderRows = tableSetting.fields.map((field) => field.title || '');
        const csvDataRows = rows.map((item) => tableSetting.fields.map((field) => dataChecking(item, field.datapath) || '-'));

        this.exportToCsv(`${tableSetting['class_name']} - ${renderDateTimeFormat(new Date(), { showSeconds: true })}`, [csvHeaderRows, ...csvDataRows]);
    },
    init() {
      if (!tableSettingConfigs[this.class_name]) {
        this.error_message = 'Initialisation failed.';
        return;
      }

      tableSettingConfigs[this.class_name].tableSettingArray.forEach((tableSetting) => {
        if (tableSetting['not_ready']) {
          return;
        }

        this.get({ tableSetting });
      });
      // this.getDebounced = _.debounce(this.get, 500);
    },
    fireDescriptorAPI({ cache_key, request_type = 'index', request_body = {}, class_name, onSuccess = null, onError = null }) {
      this.$set(this.is_class_Loading, class_name, true);

      this.$d.request(
        request_type,
        class_name,
        request_body,
        (response) => {
          const defaultOnSuccess = ({ response }) => {
            if (request_type === 'index') {
              this.$d.setCache(cache_key || `${request_type}-${class_name}`, response);

              this.$set(this.descriptor_data, class_name, response);
              this.$set(this.descriptor_error, class_name, null);
              this.$set(this.is_class_Loading, class_name, null);
            }
          };
          if (onSuccess instanceof Function) {
            onSuccess({ response, defaultOnSuccess });
          } else {
            defaultOnSuccess({ response });
          }
        },
        (error_message, cancelled) => {
          console.error(error_message);

          if (onError instanceof Function) {
            onError({ error_message, cancelled });
          } else {
            if (error_message) {
              this.descriptor_error[class_name] = error_message;
            }

            if (cancelled) {
              //do nothing if cancelled
            } else if (!this.descriptor_error[class_name]) {
              this.descriptor_error[class_name] = this.$api.defaultErrorMessage;
            }
          }
        },
        () => {
          this.is_class_Loading[class_name] = false;
        }
      );
    },
    get({ tableSetting }) {
      if (!tableSetting) {
        console.warn('tableSetting not found during get({ tableSetting }).');
      }

      this.fireDescriptorAPI({
        cache_key: `get${this.class_name}`,
        request_type: 'index',
        class_name: this.class_name,
        request_body: tableSetting.build_request_body instanceof Function ? tableSetting.build_request_body({ $d: this.$d }) : tableSetting.request_body,
        onSuccess: ({ response, defaultOnSuccess }) => {
          try {
            const dataRows = tableSetting.processDataResponse instanceof Function ?
              tableSetting.processDataResponse({ response, tableSetting, $d: this.$d })
              :
              response;
            defaultOnSuccess({ response: dataRows });
          } catch (error) {
            console.error('Fail to get data', error);
          }
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'node_modules/bootstrap/scss/functions';
@import 'node_modules/bootstrap/scss/variables';
@import 'node_modules/bootstrap/scss/mixins/_breakpoints';

.table-with-export-feature  {
  position: relative;
  min-height: 300px;

  .loading-overlay {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: RGBA(0, 0, 0, 0.2);
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 6vw;
  }

  h2 {
   font-size: 2em;
   line-height: 1em;
  }

  .table-export-not-ready,
  .table-export-no-record {
    background-color: #AAEEEE33;
    border: 1px solid darkred;
    border-radius: 5px;
    padding: 2em 1em;
  }

  .table-export-not-ready {
    background-color: #f58b2233;
  }

  .table-export-table-item {
    display: inline-block;
    max-width: 100%;
    height: calc(100vh - 365px);
    position: relative;
    overflow-x: auto;
    margin-bottom: 0.75em;

    .table-export-header {
      background-color: #AAEEEE;
      position: sticky;
      top: 0;
      font-weight: bolder;

      &.cell {
        padding: 1em 0.5em;
      }
    }

    .table-export-row-container:nth-child(even) .table-export-row {
      background-color: #AAEEEE33;
    }
    .table-export-row-container:not(:last-child) .cell {
      border-bottom-width: 0;
    }

    .table-export-header,
    .table-export-row {
      display: inline-flex;
      border: none;
      border-right: 1px solid black;

      .cell {
        display: inline-flex;
        align-items: center;
        width: 150px;
        border: 1px solid black;
        border-right-width: 0;
        padding: 0.45em 0.75em;
        white-space: nowrap;
        overflow: auto;
      }
    }
  }
}
</style>
