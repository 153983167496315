import AccountManagerOrderCommissionGroupPending from './AccountManagerOrderCommissionGroupPending.js';
import AccountManagerOrderCommissionGroup from './AccountManagerOrderCommissionGroup.js';
import AccManServiceOrderDoneCommissionGroupPending from './AccManServiceOrderDoneCommissionGroupPending.js';
import AccManServiceOrderDoneCommissionGroup from './AccManServiceOrderDoneCommissionGroup.js';
import SalespersonOrderCommissionGroupPending from './SalespersonOrderCommissionGroupPending.js';
import SalespersonOrderCommissionGroup from './SalespersonOrderCommissionGroup.js';
import DriverRtrCommissionGroupPending from './DriverRtrCommissionGroupPending.js';
import DriverRtrCommissionGroup from './DriverRtrCommissionGroup.js';
import DriverOrderDeliveredCommissionGroupPending from './DriverOrderDeliveredCommissionGroupPending.js';
import DriverOrderDeliveredCommissionGroup from './DriverOrderDeliveredCommissionGroup.js';
import AssDriverRtrCommissionGroupPending from './AssDriverRtrCommissionGroupPending.js';
import AssDriverRtrCommissionGroup from './AssDriverRtrCommissionGroup.js';
import AssDriverOrderDeliveredCommissionGroupPending from './AssDriverOrderDeliveredCommissionGroupPending.js';
import AssDriverOrderDeliveredCommissionGroup from './AssDriverOrderDeliveredCommissionGroup.js';
import TechnicianServiceOrderDoneCommissionGroupPending from './TechnicianServiceOrderDoneCommissionGroupPending.js';
import TechnicianServiceOrderDoneCommissionGroup from './TechnicianServiceOrderDoneCommissionGroupPending.js';

export default {
  AccountManagerOrderCommissionGroupPending, // tested & working
  AccountManagerOrderCommissionGroup, // got error when requesting data

  AccManServiceOrderDoneCommissionGroupPending, // no data to test but should be working
  AccManServiceOrderDoneCommissionGroup, // got error when requesting data

  SalespersonOrderCommissionGroupPending, // tested & working
  SalespersonOrderCommissionGroup, // tested but working partially (orderId column excluded to avoid error)

  DriverRtrCommissionGroupPending, // no data to test but should be working
  DriverRtrCommissionGroup, // tested & working

  DriverOrderDeliveredCommissionGroupPending, // tested & working
  DriverOrderDeliveredCommissionGroup, // got error when requesting data

  AssDriverRtrCommissionGroupPending, // no data to test but should be working
  AssDriverRtrCommissionGroup, // tested & working

  AssDriverOrderDeliveredCommissionGroupPending, // tested & working
  AssDriverOrderDeliveredCommissionGroup, // got error when requesting data

  TechnicianServiceOrderDoneCommissionGroupPending, // no data to test but should be working
  TechnicianServiceOrderDoneCommissionGroup, // no data to test but should be working
};
