import { dataChecking } from '@/descriptor/coreui/globalUtils';

const generateCommissionTableSetting = ({
  not_ready,
  title,
  class_name,
  commission_class_name,
  user_role_class_name,
  user_role_key,
  user_role_title,
  data_row_class_name,
  data_row_key,
  data_row_title,
  itemTypes,
}) => {
  return ({
    not_ready: not_ready,
    id: class_name,
    class_name: class_name,
    title: title,
    showIndexCount: true,
    // eslint-disable-next-line no-unused-vars
    build_request_body: ({ $d }) => ({
      index_withs: false,
      // with_virtual_properties: [user_role_key],
      with_properties: [],
      with_relationships: [
        {
          from_class_name: class_name,
          relationship_name: `For${user_role_class_name}`,
        },
        {
          from_class_name: class_name,
          relationship_name: 'HasCommissions',
          with_relationships: [
            ...itemTypes.map((item) => ({
              from_class_name: commission_class_name,
              relationship_name: item.relationship_name,
            })),
            {
              from_class_name: commission_class_name,
              relationship_name: data_row_class_name,
            },
          ],
        },
      ],
    }),
    fields: [
      {
        id: 'id',
        datapath: ['id'],
        title: 'Group ID',
        type: '',
        width: '80px',
      },
      {
        id: 'from_datetime',
        datapath: ['from_datetime'],
        title: 'From Datetime',
        type: '',
      },
      {
        id: 'to_datetime',
        datapath: ['to_datetime'],
        title: 'To Datetime',
        type: '',
      },
      {
        id: user_role_key,
        datapath: [user_role_key],
        title: user_role_title,
        type: '',
        width: '300px',
      },
      {
        id: data_row_key,
        datapath: [data_row_key],
        title: data_row_title,
        type: '',
      },
      {
        id: 'type',
        datapath: ['type'],
        title: 'Type',
        type: '',
      },
      {
        id: 'name',
        datapath: ['name'],
        title: 'Name',
        type: '',
        width: '300px',
      },
      {
        id: 'quantity',
        datapath: ['quantity'],
        title: 'Quantity',
        type: '',
        width: '100px',
      },
      {
        id: 'amount',
        datapath: ['amount'],
        title: 'Amount',
        type: '',
        width: '100px',
      },
    ],
    processDataResponse: ({ response, $d }) => {
      const relationship_alias = $d.getRelationAliasByNames(class_name, 'HasCommissions');
      const user_relationship_alias = $d.getRelationAliasByNames(class_name, `For${user_role_class_name}`);
      const dataRows = [];

      response.forEach((group) => {
        if (Array.isArray(dataChecking(group, relationship_alias))) {
          const user = dataChecking(group, user_relationship_alias, [0], 'name');
          const commissionArray = group[relationship_alias];

          commissionArray.forEach((commission) => {
            itemTypes.forEach((itemType) => {
              commission[$d.getRelationAliasByNames(commission_class_name, itemType['relationship_name'])].forEach((item) => {
                const newItem = {
                  id: group['id'],
                  from_datetime: group['from_datetime'],
                  to_datetime: group['to_datetime'],
                  [user_role_key]: user,
                  [data_row_key]: data_row_class_name ? commission[$d.getRelationAliasByNames(commission_class_name, data_row_class_name), 0, 'id'] : commission[data_row_key],
                  type: itemType['name'],
                  name: item['name'],
                  quantity: item[itemType.data_key]['quantity'],
                  amount: item[itemType.data_key]['amount'],
                };

                dataRows.push(newItem);
              });
            });
          });
        }
      });
      return dataRows;
    },
  });
};

export default generateCommissionTableSetting;
